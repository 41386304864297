import { encode } from 'url-safe-base64'
import sha256 from 'crypto-js/sha256';
import { Tiers } from './Features.js'

const LOCAL = false;

const APP_URL = LOCAL ? "http://127.0.0.1:8000" : "https://nig3cbs340.execute-api.us-east-1.amazonaws.com/api/";
const COGNITO_BASE_URL = LOCAL ? "http://127.0.0.1:5000" : "https://auth.heliograph.ai";
const COGNITO_URL = LOCAL ? "http://127.0.0.1:5000" : `${COGNITO_BASE_URL}/oauth2`;
const COGNITO_REDIRECT_URL = LOCAL ? "http://localhost:3000" : "https://app.heliograph.ai/";

const COGNITO = {
    tokenURL: `${COGNITO_URL}/token`,
    authURL: `${COGNITO_URL}/authorize`,
    loginURL: `${COGNITO_BASE_URL}/login`,
    logoutURL: `${COGNITO_BASE_URL}/logout`,
    clientId: "5kobnka2khqcnqt1u5vicvs504",
    redirectURL: COGNITO_REDIRECT_URL,
    maxRandNum: 4294967295, // 2^32-1
};

const APP = {
    settings: `${APP_URL}/settings`,
    bot_settings: `${APP_URL}/bot_settings`,
    faq: `${APP_URL}/faq`,
    scrape: `${APP_URL}/scrape`,
    stats: `${APP_URL}/stats`,
    history: `${APP_URL}/history`,
    subscription: `${APP_URL}/subscription`,
    new_session: `${APP_URL}/new_session`,
};

/* Base APIs */

async function secureFetch(method, url, params, body) {
    let fullURL = new URL(url);
    if (params) {
        Object.keys(params).forEach(key => {
            fullURL.searchParams.append(key, params[key]);
        })
    }

    const headers = new Headers({
        "Accept": "application/json",
        "Content-Type": "application/json",
        "Authorization": `Bearer ${getStoredToken('auth-token').id_token}`,
    });

    let requestOptions = { method: method, headers: headers, redirect: 'follow' };

    if (body) {
        requestOptions.body = JSON.stringify(body);
    }

    try {
        const response = await fetch(fullURL, requestOptions);
        if (!response.ok) {
            return { status: response.status, data: {}, message: response.message };
        }
        const content = await response.json();
        return content;
    } catch (e) {
        return { status: -1, data: {}, message: e };
    }
}

export const Client = {
    post: async (endpoint, contents) => {
        const body = Object.assign({ 'ClientID': getClientID() }, contents);
        return await secureFetch('POST', endpoint, null, body);
    },

    get: async (endpoint, extras) => {
        const params = Object.assign({ 'ClientID': getClientID() }, extras);
        return await secureFetch('GET', endpoint, params, null);
    },

    id: () => {
        return getClientID()
    },

    saveSettings: async (settings) => {
        const response = await Client.post(APP.settings, {'Settings' : JSON.stringify(settings) });
        console.log(`== POST /settings: Got response`, response);
        return response;
    },

    getSettings: async () => {
        const response = await Client.get(APP.settings);
        console.log(`== GET /settings: Got response`, response);
        return response;
    },

    ackSubscription: async () => {
        const response = await Client.post(APP.subscription, { action: 'acknowledge' });
        console.log(`== POST /subscription (ack): Got response`, response);
        return response;
    },

    cancelSubscription: async () => {
        const response = await Client.post(APP.subscription, { action: 'cancel' });
        console.log(`== POST /subscription (cancel): Got response`, response);
        return response;
    },

    getSubscription: async () => {
        const response = await Client.get(APP.subscription);
        console.log(`== GET /subscription: Got response`, response);
        return response;
    },

    getTier: async () => {
        const response = await Client.getSubscription();
        if (response.length === 0) {
            return Tiers[0]
        }
        const tierId = response[0].subscription_items.item_price_id;
        return Tiers[tierId];
    },

    getWeeklyStats: async (numWeeks) => {
        let today = new Date();
        const first = today.getDate() - today.getDay(); // First day is the day of the month - the day of the week
        const last = first + 6 + 7 * (numWeeks - 1); // last day is the first day + 6
        const weekStart = new Date(today.setDate(first)).toUTCString();
        const weekEnd = new Date(today.setDate(last)).toUTCString();

        const response = await Client.get(APP.stats, { StartTime: weekStart, EndTime: weekEnd });
        console.log(`== GET /stats: Got response`, response);
        return response.data;
    },
};

export const Bot = {
    post: async (endpoint, id, contents) => {
        const body = Object.assign({ 'BotID': getBotID(id) }, contents);
        return await secureFetch('POST', endpoint, null, body);
    },

    get: async (endpoint, id, extras) => {
        const params = Object.assign({ 'BotID': getBotID(id) }, extras);
        return await secureFetch('GET', endpoint, params, null);
    },

    getHistory: async (id, day, count, offset) => {
        const startTs = new Date(day.getFullYear(), day.getMonth(), day.getDate(), 0, 0);
        const endTs = new Date(day.getFullYear(), day.getMonth(), day.getDate(), 23, 59);
        const response = await Bot.get(APP.history, id, {
            StartTime: startTs, EndTime: endTs,
            CountPerPage: count, PageOffset: offset });
        console.log(`== GET /history: Got response`, response);
        return response.data;
    },

    startScraping: async (id, url, max_children, text, file) => {
        let scrapeRequests = [];
        if (file)
            scrapeRequests.push({
                type: "file",
                url: file.name,
                max_children: 1,
                parallel: true,
                data: file.data,
            });
        if (text)
            scrapeRequests.push({
                type: "text",
                url: "",
                max_children: 1,
                parallel: true,
                data: text,
            });
        if (url)
            scrapeRequests.push({
                type: "url",
                url: url,
                max_children: max_children,
                parallel: true,
                data: ""
            });
        const response = await Bot.post(APP.scrape, id, scrapeRequests);
        console.log(`== POST /scrape: Got response`, response);
        return response;
    },

    getScrapeStatus: async (id) => {
        const response = await Bot.get(APP.scrape, id);
        console.log(`== GET /scrape: Got response`, response);
        return response.data;
    },

    saveSettings: async (id, settings) => {
        const response = await Bot.post(APP.bot_settings, id, {'Settings' : JSON.stringify(settings) });
        console.log(`== POST /settings: Got response`, response);
        return response;
    },

    getSettings: async (id) => {
        const response = await Bot.get(APP.bot_settings, id);
        console.log(`== GET /settings: Got response`, response);
        return response.data;
    },

    saveFAQ: async (id, faqs) => {
        const response = await Bot.post(APP.faq, id, { 'FAQs' : JSON.stringify(faqs) });
        console.log(`== POST /faq: Got response`, response);
        return response;
    },
    
    getFAQ: async (id) => {
        const response = await Bot.get(APP.faq, id);
        console.log(`== GET /faq: Got response`, response);
        return response.data;
    }
};

/* File reader */
export async function readFromFiles(fileList) {
    const promises = Array.from(fileList).map(file => new Promise((resolve, reject) => {
        if (file) {
            let reader = new FileReader();
            reader.onload = () => { resolve({ name: file.name, data: reader.result }) };
            reader.onerror = () => { reject({ name: file.name, error: `error reading ${file}` }) };
            reader.readAsText(file, "UTF-8");
        } else {
            reject(`No such ${file}`);
        }
    }));

    return await Promise.allSettled(promises);
}


/* OAuth 2.0: Authorization code + PKCE Flow */

export function getStoredToken(name) {
    const now = new Date();
    const tokenObject = JSON.parse(sessionStorage.getItem(name));
    if (!tokenObject) {
        return null;
    }

    const deltaMinutes = (tokenObject.expiresAt.valueOf() - now.valueOf()) / 60000;
    if (deltaMinutes >= 60) {
        console.log(`Token expired. Token life is ${deltaMinutes} minutes`);
        sessionStorage.removeItem(name);
        return null;
    }

    return tokenObject.token;
}

export function clearStoredToken(name) {
    sessionStorage.removeItem(name);
}
  
export function storeToken(name, token) {
    var expires = new Date();
    expires.setHours(expires.getHours() + 1);
    var tokenObject = {
        expiresAt: expires,
        token: token
    }
    sessionStorage.setItem(name, JSON.stringify(tokenObject));
}
function base64urlencode(arg)
{
  var s = btoa(arg); // Regular base64 encoder
  s = s.split('=')[0]; // Remove any trailing '='s
  s = s.replaceAll('+', '-'); // 62nd char of encoding
  s = s.replaceAll('/', '_'); // 63rd char of encoding
  return s;
}

function toBytesInt32 (num) {
    return [
         (num & 0xff000000) >> 24,
         (num & 0x00ff0000) >> 16,
         (num & 0x0000ff00) >> 8,
         (num & 0x000000ff)
    ];
}

function generateNewChallenge() {
    const array = new Uint8Array(40);
    crypto.getRandomValues(array);
    let codeString =  String.fromCharCode.apply(null, array);
    let codeVerifier = base64urlencode(codeString);
    let sha = sha256(codeVerifier);
    let shaBytes = sha.words.map(toBytesInt32).flat(1).map(t => t&0xff );
    let codeChallenge = base64urlencode(String.fromCharCode.apply(null, shaBytes));
    return { challenge: codeChallenge, verifier: codeVerifier };
}

export function startAuthorization() {
    const challengePair = generateNewChallenge();
    storeToken('hb-challenge', challengePair);

    var authURL = new URL(COGNITO.authURL);
    authURL.searchParams.append("client_id", COGNITO.clientId);
    authURL.searchParams.append("response_type", "code");
    authURL.searchParams.append("redirect_uri", COGNITO.redirectURL);
    authURL.searchParams.append("code_challenge", challengePair.challenge);
    authURL.searchParams.append("code_challenge_method", "S256");
    window.location.href = authURL;
}

export async function getNewUserToken(authCode) {
    const challengePair = getStoredToken('hb-challenge');
    if (!challengePair) {
        return null;
    }

    const headers = new Headers({ "Content-Type": "application/x-www-form-urlencoded" });
    const body = new URLSearchParams({
        "grant_type": "authorization_code",
        "client_id": COGNITO.clientId,
        "redirect_uri": COGNITO.redirectURL,
        "code": authCode,
        "code_verifier": challengePair.verifier,
    });

    try {
        const response = await fetch(COGNITO.tokenURL, {
                method: 'POST',
                headers: headers,
                redirect: 'follow',
                body: body
        });

        if (!response.ok) {
            return null;
        }
        const content = await response.json();
        return content;
    } catch (e) {
        return null;
    }
}

export function isUserLoggedIn() {
    const authToken = getStoredToken('auth-token');
    console.log("isUserLoggedIn authToken=", authToken);
    return !!authToken;
}

export function pendingAuthorization() {
    const authCode = new URLSearchParams(window.location.search).get("code");
    const challengePair = getStoredToken('hb-challenge');
    console.log("pendingAuthorization authCode=", authCode);
    return !!authCode && !!challengePair;
}

export async function completeAuthorization() {
    const authCode = new URLSearchParams(window.location.search).get("code");
    console.log("completeAuthorization authCode=", authCode);
    const authToken = await getNewUserToken(authCode);
    console.log("completeAuthorization authToken=", authToken);
    storeToken('auth-token', authToken);
}

export async function logoutUser() {
    clearStoredToken('hb-challenge');
    clearStoredToken('auth-token');

    var logoutUrl = new URL(COGNITO.logoutURL);
    logoutUrl.searchParams.append("client_id", COGNITO.clientId);
    logoutUrl.searchParams.append("response_type", "code");
    logoutUrl.searchParams.append("redirect_uri", encodeURIComponent("https://heliograph.ai"));
    window.location.href = logoutUrl;
}

function decodeJWTToken(token) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
}

export function getClientID() {
    const authToken = getStoredToken('auth-token');
    const payload = decodeJWTToken(authToken.access_token);
    console.log("getClientID", payload);
    return payload.sub;
}

export function getClientEmail() {
    const authToken = getStoredToken('auth-token');
    const payload = decodeJWTToken(authToken.id_token);
    return payload.email;
}

export function getBotID(id) {
    return getClientID() + "-" + id;
}

/* General Utilities */
export async function delay(millisec) {
    return new Promise(resolve => {
        setTimeout(() => { resolve('') }, millisec);
    })
}

export default function DummyClient() {}
