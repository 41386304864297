import React, { useEffect, useState } from "react";
import { Tabs, Pagination } from './Components.jsx'
import Calendar from 'react-calendar'
import 'react-calendar/dist/Calendar.css';
import { Bot, Client } from './HGClient'
import { getSettingsForTier } from './Features.js'

const defaultBotList = [
  { name: 'Bot 1', href: '#', id: 1, current: true },
  { name: 'Bot 2', href: '#', id: 2, current: false },
  { name: 'Bot 3', href: '#', id: 3, current: false },
  { name: 'Bot 4', href: '#', id: 4, current: false },
  { name: 'Bot 5', href: '#', id: 5, current: false },
];

const names = {
  human: "User: ",
  bot: "Heliobot: "
};

function ChatSession({session}) {
    return (
      <ul className="mb-8 bg-grey-950">
        {session.map((event, eventIdx) => (
          <li className="p-1" key={eventIdx}>
            <p className="font-small text-gray-700 whitespace-normal"> 
              <span className="font-bold">{names[event.who]}</span>
              {event.content}
            </p>
          </li>
        ))}
      </ul>
    )
}

export default function ChatHistory() {
    const [bots, setBots] = useState(defaultBotList);
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [showCalendar, setShowCalendar] = useState(false);
    const [sessions, setSessions] = useState([]);
    const [error, setError] = useState("");
    const [pages, setPages] = useState({
      current: 1, // current page, 1-indexed
      offset: 0,  // offset of items in total
      count: 10,  // items in current page
      total: 1,   // total items
    });

    function selectPage(index) {
      const last = Math.floor(pages.total / pages.count) + 1, first = 1;
      const page = Math.max(first, Math.min(index, last));
      const offset = (page - 1) * pages.count;
      
      setPages({
        ...pages,
        current: page,
        offset: offset,
      });
    }

    useEffect(() => {
      async function getBotSettings() {
        let settings = await Client.getSettings();
        if (settings.length === 0) {
          settings = getSettingsForTier(await Client.getTier());
        }
        setBots(settings.bots);
      }

      getBotSettings();
    }, []);

    useEffect(() => {
      async function getSessions() {
        Bot.getHistory(bots.filter(b => b.current)[0].id, selectedDate, pages.count, pages.offset)
           .then(history => {
              setSessions(history.Chats);
              setPages({
                ...pages,
                total: history.TotalCount
              });
           })
           .catch(e => setError(e))
      };

      getSessions();
    }, [selectedDate, bots, pages]);

    function selectBot(e) {
      const element = e.target.tagName === 'SPAN' ? e.target.parentElement : e.target;
      var name = element.attributes['data-name'].value;
      if (name === "tabs-selector") {
        name = element.value;
      }
      setBots(bots.map(obj => ({...obj, current: name === obj.name})));
    }

    return (
        <>
        <Tabs tabs={bots} onClick={selectBot} options={{title:"Select your bot", selector: true, tabbed: false}}/>
        <h3 className="mt-8 text-lg leading-6 font-medium text-gray-900">
          All Chat Sessions from
          <a href="#" className="px-2 underline decoration-dotted text-indigo-900 italic"
             onClick={() => setShowCalendar(!showCalendar)}>
            {selectedDate.toString()}
          </a>
        </h3>

        <p className="text-red-500 italic">{error}</p>

        {showCalendar && <Calendar className="mt-5" onChange={setSelectedDate} value={selectedDate} />}
        <ul className="space-y-3 mt-4">
            {sessions.map((session, sessionIndex) => (
                <li key={sessionIndex} className="mt-1 bg-white shadow rounded-md px-4 whitespace-normal break-words">
                    <ChatSession session={session} />
                </li>
            ))}
        </ul>
        <Pagination pages={pages} selectPage={selectPage} />
        </>
    )
}
