import { TextArea } from "./Components.jsx"
import { useState, useEffect } from "react";

const helpText = `
Enter upto 50 frequently asked questions and answers for HelioBot.
All language models (LMs) make up answers when they're unsure. We use FAQs to improve the accuracy of OpenAI's GPT and reduce hallucination
Click on the FAQ section of the sidebar to enter common Q&A which HelioBot can use to improve its answers
HelioBot auto-generates additional FAQs as your users interact with it.
`;

var faqs = {
  data: "",
  generated: "",
  embed: '<script id="customerid" src="https://js.heliograph.ai/faq.js?id=customerid" />'
}

export default function FAQ() {
  const [saveState, setSaveState] = useState("");
  const saveFAQs = async (e) => {
    setSaveState("Saving");
    e.preventDefault();
  }

  useEffect(() => {

  });

  return (
    <div>
        <h3 className="text-xl leading-6 font-medium text-gray-900 mb-5">Frequently Asked Questions</h3>
        <p className="mt-1 text-md text-gray-600">All language models (LMs) make up answers when they're unsure. We use FAQs to improve the accuracy of OpenAI's GPT and reduce hallucination</p>
        <p className="mt-1 text-md text-gray-600">HelioBot auto-generates additional FAQs as your users interact with it</p>
        <p className="mt-1 text-md text-gray-500 mt-5">Each entry in the FAQ box should be 2 lines in the format of Q:question A:answer. For example:</p>
        <p className="mt-1 text-md text-gray-500">Q:What is Batman's real name?</p>
        <p className="mt-1 text-md text-gray-500 mb-10">A:Batman's real name is Bruce Wayne.</p>
        <TextArea options={{
            name: "given-faq",
            rows: 5,
            value: faqs.data,
            placeholder: "Q:What is Batman's real name?\nA:Batman's real name is Bruce Wayne.",
            label: "Frequently Asked Questions",
            pretext: "Enter upto 50 frequently asked questions and answers for HelioBot."
        }} />
        <button
            type="button"
            onClick={saveFAQs}
            className="mt-2 inline-flex justify-center py-1 px-5 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
            Save
        </button>
        <span className="ml-5 font-bold text-gray-500">{saveState}</span>

        <h3 className="text-m leading-6 font-medium text-gray-900 mt-10">FAQ generated by HelioBot</h3>
        <p className="mt-1 text-md text-gray-500">Here are the FAQ's generated by HelioBot.</p>
        <TextArea options={{
            name: "autogen-faw",
            rows: 3,
            value: faqs.generated,
            placeholder: "None",
            label: "",
        }} />
        <p className="mt-1 text-md text-gray-500">Copy this script tag and paste it in your website's HTML page</p>
              <p className="mt-1 text-md font-bold text-red-500">Make sure you allow chat.heliograph.ai in your Cross-Origin Header</p>

        <div className="pl-2 pt-2 pb-2 mt-2 border border-indigo-300">
        <p className="font-mono">{faqs.embed}</p>
        </div>
    </div>
  )
}
