import React, { useEffect, useState } from "react";
import { TextBoxWithLabel, ColorPicker, TextArea, Tabs, ModalDialog, deepCopy } from "./Components.jsx"
import { RefreshIcon } from '@heroicons/react/solid'
import { Client, Bot, delay, readFromFiles } from './HGClient.js'
import { getSettingsForTier, updateBotEmbeds } from './Features.js'

const defaultSettings = {
  name : "Heliobot",
  initialMessage : "Hi! What can I help you with today?",
  suggestedMessages : "How can I get refunds?\nWhat products do you offer?\nhttps://google.com/maps",
  botColor : "#1E3A8A",
  primaryURL : "",
  bots: [
    { name: 'Bot 1', href: '#', current: true },
    { name: 'Bot 2', href: '#', current: false },
    { name: 'Bot 3', href: '#', current: false },
    { name: 'Bot 4', href: '#', current: false },
    { name: 'Bot 5', href: '#', current: false },
  ],
  nBots: 5
};

const IDNameMap = [
  ["bot-name", "name"], ["initial-msg", "initialMessage"], ["suggested-msgs", "suggestedMessages"], ["bot-color", "color"],
  ["primary-url", "url"], ["input-raw-text", "text"]
];

export default function ChatbotSettings() {
  const [openModal, setOpenModal] = useState(false);
  const [saveState, setSaveState] = useState("");
  const [settings, setSettings] = useState(defaultSettings);

  const currentBot = () => settings.bots.filter(bot => bot.current)[0];

  /* Function that runs when this component is mounted
   * We sync all settings from the cloud on mount */
  useEffect(() => {
    const syncSettings = async () => {
      let settings = await Client.getSettings();
      if (settings.status != 200 || settings.data.length === 0) {
        const tier = await Client.getTier();
        settings = getSettingsForTier(tier);
        settings = updateBotEmbeds(settings, Client.id());
      } else {
        settings = settings.data;
      }
      settings.bots = settings.bots.map((bot, i) => ({...bot, current: i === 0}));
      setSettings(settings);
    };

    syncSettings();
  }, []);

  async function saveBotSettings(e) {
    setSaveState("Starting saving...");

    // 1. Create a copy of the existing settings (for React's sake)
    let curSettings = deepCopy(settings);
    let curBot = curSettings.bots.filter(bot => bot.current)[0];

    // 2. Get the current value of all elements and store in the settings object
    IDNameMap.forEach(([id, field]) => {
      curBot[field] = document.getElementById(id).value;
    });

    // 3. Start scraping if necessary
    let scrapedList = await Bot.getScrapeStatus(curBot.id);
    if (scrapedList.length === 0) {
      setSaveState("Starting scraping...");
      await Bot.startScraping(curBot.id, curBot.url, settings.nURLs);
      scrapedList = await Bot.getScrapeStatus(curBot.id);
    }
  
    // 4. Read file data
    let fileData = await readFromFiles(document.getElementById("formFile").files);
    fileData.length = Math.min(fileData.length, settings.nFiles);
    curBot.files = fileData;

    // 5. We have everything. Now save the client and bot
    await Client.saveSettings(curSettings);
    await Bot.saveSettings(curBot.id, curBot);

    // 6. Finally sync UI state
    setSettings(curSettings);
    setSaveState("All saved");

    e.preventDefault();
  }

  function selectBot(e) {
    const element = e.target.tagName === 'SPAN' ? e.target.parentElement : e.target;
    var name = element.attributes['data-name'].value;
    if (name === "tabs-selector") {
      name = element.value;
    }
    setSettings(settings.bots.map(obj => ({...obj, current: name === obj.name})));
    setSaveState("");
  }

  function getTitle() {
    return "All scraped URLs from " + (currentBot().url || "your website");
  }

  return (
    <>
    <ModalDialog options={{
        open: openModal,
        setOpen: setOpenModal,
        title: "Status of the Scraper",
        content: () => (<URLStatusList botID={currentBot().id} title={getTitle()}/>),
        primary: "OK",
      }} />
    <Tabs tabs={settings.bots} onClick={selectBot} options={{title:"Select your bot", selector: true, tabbed: false}}/>
    <SingleChatbotSetting settings={currentBot()} saveState={saveState} setOpenModal={setOpenModal} saveSettingsForm={saveBotSettings}/>
    </>
  )
}

function URLStatusList({botID, title}) {
  const [urlStatus, setUrlStatus] = useState([]);

  const statusColor = (status) => {
    switch(status) {
      case "Fail" :
        return "inline-flex items-center rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/20";
      case "In Progress" :
        return "inline-flex items-center rounded-md bg-yellow-50 px-2 py-1 text-xs font-medium text-yellow-700 ring-1 ring-inset ring-yellow-600/20";
      default:
      case "OK" :
          return "inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20";
    }
  };

  async function rescrapeURL(e) {
    const selectedURL = e.srcElement.attributes['data-url'].value;
    setUrlStatus(urlStatus.map(urlSts => ({ ...urlSts, status: urlSts.url === selectedURL ? "In Progress" : urlSts.status})));
    await Bot.startScraping(botID, selectedURL, 0);
    await delay(3000);
    const scrapedList = await Bot.getScrapeStatus(botID);
    setUrlStatus(scrapedList.map(url => ({url: url, status: "OK" })));
  }

  /* This runs when we mount this component */
  useEffect(() => {
    const syncUrlSts = async () => {
      const scrapedList = await Bot.getScrapeStatus(botID);
      setUrlStatus(scrapedList.map(url => ({url : url, status : "OK" })));
    };
    syncUrlSts();
  }, [botID]);

  return ( <div>
    <p className="mt-1 text-sm text-gray-500 sm:col-span-4">{title}</p>
    <ul className="divide-y divide-gray-100 py-6">
      {urlStatus.map(url => (
        <li className="flex justify-between gap-x-6 py-3">
          <a className="text-indigo-500" href={url.url}>{url.url}</a>

          <div className="flex justify-left gap-x-3">
            <span className={statusColor(url.status)}>{url.status}</span>
            {(url.status !== "In Progress") &&
              <button type="button"
                onClick={rescrapeURL}
                data-url={url.url}
                className="inline-flex items-center p-1 border border-transparent rounded-full shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                <RefreshIcon className="h-5 w-5" aria-hidden="true" />
              </button>}
          </div>
      </li>))}
    </ul>
    </div>)
}

function SingleChatbotSetting({bot, saveState, setOpenModal, saveSettingsForm}) {
    return (
      <form className="space-y-8 divide-y divide-gray-300">
        <div className="space-y-8 divide-y divide-gray-300">
          <div className="pt-8">
            <div>
              <h3 className="text-lg leading-6 font-medium text-gray-900">Embedding Script</h3>
              <p className="mt-1 text-sm text-gray-500">Copy this script tag and paste it in your website's HTML page</p>
              <p className="mt-1 text-sm font-bold text-red-500">Make sure you allow chat.heliograph.ai in your Cross-Origin Header</p>
              <div className="pl-2 pt-2 pb-2 mt-2 border border-indigo-300">
                <p className="font-mono">{bot.embed}</p>
              </div>
            </div>
          </div>

          <div className="pt-4">
            <div>
              <h3 className="text-lg leading-6 font-medium text-gray-900">Interface Settings</h3>
              <p className="mt-1 text-sm text-gray-500">How will Heliobot identify and interact with your users?</p>
            </div>
            <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                {bot.canCustomize && <TextBoxWithLabel options={{label: "Heliobot's name (as shown to your users)", type: "text", name: "bot-name", value: bot.name }} />}
                <TextBoxWithLabel options={{label: "Welcome Message",     type: "text", name: "initial-msg", value: bot.initialMessage }} />
                <TextArea options={{
                  name: "suggested-msgs",
                  rows: 3,
                  value: bot.suggestedMessages,
                  label: "Initial suggested messages",
                  subtext: "These are shown before the first question is asked. Enter upto 3 messages, one per line. You can specify URLs as well"
                }} />
                {bot.canCustomize && <ColorPicker options={{id: "bot-color", label:"Chat bubble color", subtext:"All messages from the bot are in this color", value: bot.color}} />}
            </div>
          </div>

          <div className="pt-6">
            <div>
              <h3 className="text-lg leading-6 font-medium text-gray-900">Website, Documents and Text Data</h3>
              <p className="mt-1 text-sm text-gray-500">Data that Heliobot uses during chat</p>
            </div>

            <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                <TextBoxWithLabel options={{label: "Primary URL to start scraping from", type: "text", name: "primary-url", grid: "sm:col-span-6", value: bot.url}} />
                <button
                  type="button"
                  onClick={(e) => setOpenModal(true)}
                  className="sm:col-span-2 inline-flex justify-center py-1 px-2 text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  Status of the Scraper
                </button>
            </div>

            <div className="mt-6 grid grid-cols-1 gap-y-3 gap-x-4 sm:grid-cols-6">
              <label className="block sm:col-span-6 text-sm font-medium text-gray-700">
                Documents with any additional data. You can attach upto {bot.nFiles} files.
              </label>
              <input className="sm:col-span-3 border border-indigo-500 bg-indigo-100 rounded-sm p-2" type="file" id="formFile" multiple="multiple"/>
            </div>

            <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
              <TextArea options={{
                  name: "input-raw-text",
                  rows: 3,
                  label: "Raw text data",
                  subtext: "Use this to give FAQs, clarifications, product suggestions etc",
                  value: bot.text,
                }} />
            </div>

          </div>

        </div>
  
        <div className="pt-5">
          <div className="flex justify-start">
            <button
              type="button"
              onClick={saveSettingsForm}
              className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Save Bot {bot.id}
            </button>
            <span className="py-2 ml-5 font-bold text-gray-500">{saveState}</span>
            {bot.showCancel &&
             (<button
              type="button"
              className="ml-3 bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
               Cancel
              </button>)}
          </div>
        </div>
      </form>
    )
  }
  