import { useEffect, useState } from 'react';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip } from 'recharts';
import { Bot } from './HGClient'

export default function Dashboard() {
    const [cards, setCards] = useState([]);
    const [charts, setCharts] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            setCards(await Bot.getWeeklyStats(1));
        };

        fetchData();
    }, []);
    
    return (
    <div>
        {cards.map((card, botIndex) => (
            <>
            <h3 className="text-lg leading-6 font-medium text-gray-900">Bot {botIndex + 1} - Last 30 days</h3>
            <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-3">
            {card.Cards.map((item, index) => (
                <div key={index} className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
                    <dt className="text-sm font-medium text-gray-500 truncate">{item.name}</dt>
                    <dd className="mt-1 text-3xl font-semibold text-gray-900">{item.stat}</dd>
                </div>
            ))}
            </dl>
            </>
        ))}

        {charts.length > 0 &&
        <>
        <h3 className="text-lg leading-6 font-medium text-gray-900 pt-5 mt-2">Usage Statistics</h3>
        <div className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-6">
            {charts.map(chart => (
            <AreaChart width={500} height={400} data={chart} className='pt-5 sm:col-span-3'>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Area type="monotone" dataKey="uv" stackId="1" stroke="#8884d8" fill="#8884d8" />
                <Area type="monotone" dataKey="pv" stackId="1" stroke="#82ca9d" fill="#82ca9d" />
                <Area type="monotone" dataKey="amt" stackId="1" stroke="#ffc658" fill="#ffc658" />
            </AreaChart>
            ))}
        </div>
        </>}
    </div>)
}