import { Table } from "./Components.jsx"

const leads = [
    [ "aaa@gmail.com", "https://linkedin/aaa", "Acme Inc", "90%"],
    [ "aaa@gmail.com", "https://linkedin/aaa", "Acme Inc", "90%"],
    [ "aaa@gmail.com", "https://linkedin/aaa", "Acme Inc", "90%"],
    [ "aaa@gmail.com", "https://linkedin/aaa", "Acme Inc", "90%"],
  ];

export default function Leads() {
    return (
    <div className="pt-8">
    <div>
        <h3 className="text-lg leading-6 font-medium text-gray-900">All Leads</h3>
        <p className="mt-1 text-sm text-gray-500">A list of Qualified Leads</p>
    </div>
    <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
        <Table options={{
            header: ["email address", "LinkedIn", "Company", "Confidence"],
            edit: false,
            style: "sm:col-span-6",
            rows: leads
        }} />
        
    </div>
    </div>)
}