import './App.css'
import { useEffect, useState } from "react";
import Outline, { ModalDialog } from './Components.jsx'
import ChatbotSettings from './ChatbotSettings.jsx'
import Dashboard from './Dashboard.jsx'
import Leads from './Leads.jsx'
import ChatHistory from './ChatHistory.jsx'
import Integrations from './Integrations.jsx'
import Pricing from './Pricing.jsx'
import { CogIcon, PresentationChartBarIcon, PuzzleIcon, ChatAlt2Icon, UsersIcon, ColorSwatchIcon, LogoutIcon, AcademicCapIcon } from '@heroicons/react/outline'
import FAQ from './FAQ.jsx'
import { Client } from './HGClient'
import { startAuthorization, isUserLoggedIn, pendingAuthorization, completeAuthorization, logoutUser } from './HGClient'

var renderCount = 0;
const State = { Init: 0,
                UserLoggedInNeedSync: 1, UserLoggedInSyncDone: 2,
                StartAuthorization: 3, PendingAuthorization: 4,
                CheckTokenValidity: 5 };

let navigation = [
  { enabled: false, name: 'Dashboard', icon: PresentationChartBarIcon, content: (so) => { return (<Dashboard />) } },
  { enabled: false, name: 'Chat History', icon: ChatAlt2Icon, content: (so) => { return (<ChatHistory />) } },
  { enabled: false, name: 'Heliobot Settings', icon: CogIcon, content: (so) => { return (<ChatbotSettings />) } },
  { enabled: false, name: 'FAQ', icon: AcademicCapIcon, content: (so) => { return (<FAQ />) } },
  { enabled: false, name: 'Integrations', icon: PuzzleIcon, content: (so) => { return (<Integrations />) } },
  { enabled: true,  name: 'My Plan', icon: ColorSwatchIcon, content: (so) => { return (<Pricing />) } },
  { enabled: false, name: 'Leads', icon: UsersIcon, content: (so) => { return (<Leads />) } },
  { enabled: true,  name: 'Logout', icon: LogoutIcon, content: (so) => { return (<div>Logging out...</div>) }, click: () => { logoutUser(); } },
];

function App() {
  const [state, setState] = useState(State.Init);
  const [dialogOpen, setDialogOpen] = useState(state === State.PendingAuthorization);
  const [outline, setOutline] = useState({nav: navigation, show: 'My Plan'});

  console.log(`[App.js] Render count ${renderCount}. State ${state}`);
  renderCount++;

  useEffect(() => {
    window.onhashchange = (e) => {
      setState(State.UserLoggedInNeedSync);
    }

    function navigationFrom(items, preferredView) {
      const newNavigation = navigation.map(n => ({...n, enabled: items.includes(n.name)}));
      const panelToShow = preferredView || newNavigation[0].name;
      return {nav: newNavigation, show: panelToShow}
    }

    switch (state) {
      case State.Init:
        const newState = isUserLoggedIn() ? State.UserLoggedInNeedSync : (
                            pendingAuthorization() ? State.PendingAuthorization : State.StartAuthorization
                          );
        setState(newState);
        setOutline(navigationFrom(['My Plan', 'Logout']));
        break;

      case State.UserLoggedInNeedSync:
        const syncState = async () => {
          try {
            const subscription = await Client.getSubscription();
            if (subscription.status !== 200) {
              console.log("No subscription found", subscription);
              setOutline(navigationFrom(['My Plan', 'Logout']));
              return;
            } 
  
            const settings = await Client.getSettings();
            if (settings.status !== 200 || settings.data.length == 0) {
              console.log("No settings found", settings);
              setOutline(navigationFrom(['My Plan', 'Heliobot Settings', 'Logout'], 'Heliobot Settings'));
            }
            else {
              console.log("Active subscription found", subscription);
              setOutline(navigationFrom(['Dashboard', 'Chat History', 'Heliobot Settings', 'My Plan', 'Logout']));
            }
          }
          catch (error) {
            console.log("API Error", error);
            setOutline(navigationFrom(['My Plan', 'Logout']));
          }
          setState(State.CheckTokenValidity);
        };

        syncState();
        break;

      case State.StartAuthorization:
        startAuthorization();
        break;

      case State.PendingAuthorization:
        completeAuthorization().then(() => setState(State.UserLoggedInNeedSync));
        break;

      default:
      case State.CheckTokenValidity:
        break;
    }
  }, [state]);

  switch(state)
  {
    case State.UserLoggedInNeedSync:
    case State.CheckTokenValidity:
      return (
        <Outline options={outline}/>
      );

    case State.PendingAuthorization:
      return (
        <ModalDialog options={{
          open: dialogOpen,
          setOpen: setDialogOpen,
          title: "Logging In",
          content: () => (
            <p>Waiting to complete login..</p>
          )
        }} />
      );

    default: break;
  }
}

export default App;