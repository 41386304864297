export const Tiers = [
    { name: 'Entry', priceMonthly: 19.99, description: 'Low-traffic websites',
      features: {  nChats: '2000', nBots: '5', nURLs : '100', files: '1', text: true,
                   support: false, leadGen: false, leadQual: false,
                   thirdParty: false,
                   humanFallback: false, stats: true, customize: false } },
  
    { name: 'Standard', priceMonthly: 49.99, description: 'Medium-traffic + Leads + Plugins',
      features: {  nChats: '5000', nBots: '5', nURLs : '100', files: '5', text: true,
                   support: false, leadGen: true, leadQual: true,
                   thirdParty: "WordPress Plugin, Hubspot, Segment",
                   humanFallback: true, stats: true, customize: false } },
  
    { name: 'Premium', priceMonthly: 99.99, description: 'High traffic + everything',
      features: {  nChats: '10000', nBots: '10', nURLs : '100', files: '5', text: true,
                   support: "Email Support (1 day)", leadGen: true, leadQual: true,
                   thirdParty: "WordPress Plugin, Hubspot, Segment",
                   humanFallback: true, stats: true, customize: true } },
  
    { name: 'Deluxe', priceMonthly: 399.99, description: 'Very high traffic + everything',
      features: {  nChats: '40000', nBots: '20', nURLs : '100', files: '20', text: true,
                   support: "Email Support (1 day)", leadGen: true, leadQual: true,
                   thirdParty: "WordPress Plugin, Hubspot, Segment",
                   humanFallback: true, stats: true, customize: true } },
  ];
  
  export const FeatureDescription = {
    nChats: n => `${n} chat questions/month`,
    nBots: n => `${n} bots`,
    nURLs: n => `${n} scraped web pages`,
    files: n => `${n} file uploads`,
    text: n => `Additional text data`,
    stats: n => n ? `Usage stats for your bots` : ``,
    leadGen: n => n ? `Lead Collection`: ``,
    leadQual: n => n ? `Lead Qualification` : ``,
    humanFallback: n => n ? `Fall back to humans when AI cannot answer` : ``,
    thirdParty: n => n || ``,
    support: n => n || ``,
    customize: n => n ? `Customize your Heliobot` : ``,
  }

  export const updateBotEmbeds = (settings, clientID) => {
    settings.bots = settings.bots.map((bot, i) => ({
        ...bot,
        embed: `<script id="${clientID}-${i}" src="https://chat.heliograph.ai/heliobot-min.js" />`
    }));
    return settings;
  }

  export const getSettingsForTier = (tier) => {
    let settings = {
        nChats: parseInt(tier.features.nChats),
        nBots: parseInt(tier.features.nBots),
        nURLs: parseInt(tier.features.nURLs),
        nFiles: parseInt(tier.features.files),
        hasText: tier.features.text,
        leadGen: tier.features.leadGen,
        leadQual: tier.features.leadQual,
        hasStats: tier.features.stats,
    };

    settings.bots = [...Array(settings.nBots)].map((_, i) => ({
        name: `Bot ${i+1}`,
        href: '#',
        id: i+1,
        current: false,
        url: "",
        canCustomize: tier.features.customize,
        color: "#1E3A8A",
        initialMessage: "Hi! What can I help you with today?",
        suggestedMessages: "How can I get refunds?\nWhat products do you offer?\nhttps://google.com/maps",
        files: [...Array(settings.nFiles)].map(_ => ""),
        text: "",
    }));

    return settings;
  }
  
  export const featureList = Object.keys(FeatureDescription);
  
  export const getFeatures = (features) => featureList.map(f => FeatureDescription[f](features[f])).filter(x => x.length > 0)

  export default function Dummy() {}