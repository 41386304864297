import Pricify from '@chargebee/atomicpricing';
import { useEffect } from 'react';

export default function Pricing() {
  useEffect(() => {
    Pricify.init();
  }, []);

  return (
    <div
      id="pricify-hosted-pricing-page"
      data-pricify-site="01H20A2063ZV29K0Z8MVEBQKPP"
      data-pricify-pricingpage="default"
    ></div>
  );
}




// import React, { useState, useRef, useEffect } from 'react';
// import { CheckIcon as CheckIconSolid } from '@heroicons/react/solid'
// import { Client } from './HGClient.js'
// import { Tiers, getFeatures } from './Features.js'

// export default function Pricing() {
//   const [loading, setLoading] = useState(false);
//   const [activeTier, setActiveTier] = useState('Standard');
//   const cbInstance = useRef(null);

//   const fetchActiveTier = async () => {
//     const tier = await Client.getTier();
//     setActiveTier(tier.name);
//   };

//   useEffect(() => {
//     fetchActiveTier();
//   }, []);

//   /* Handle Subscription changes */
//   function createOrUpdateSubscription (event) {
//     if (!cbInstance.current) {
//       return false;
//     }

//     const selectedPlanName = event.target.innerText.split(' ')[1];
//     const selectedId = Tiers.map((tier, i) => [tier, i]) // map tier -> [tier, index]
//                             .filter(([tier, i]) => tier.name === selectedPlanName)[0][1]; // get index where tier.name == selected

//     setLoading(true);
//     event.preventDefault();
//     cbInstance.current.openCheckout({
//       hostedPage: () => {
//         var data = {
//           item_price_id: selectedId,
//           action: "subscribe"
//         };
//         return Client.getChargeBeeHostedPage(data);
//       },
//       success(hostedPageId) {
//         Client.ackSubscription().then(fetchActiveTier);
//         console.log("HOSTED PAGE ID", hostedPageId);
//       },
//       close:() => {
//         setLoading(false);
//         console.log("CHECKOUT CLOSED");
//       },
//       step(step) {
//         console.log("CHECKOUT STEP", step);
//       }
//     });
//   }

//   /* ChargeBee API Init */
//   function createChargeBeeInstance() {
//     if (!cbInstance.current)
//     {
//       console.log("Creating chargebee instance");
//       cbInstance.current = window.Chargebee.init({
//         site: "heliograph",
//         publishableKey: "test_kQd5OcHpZludYcuhSzs1wNsXcSuIzAVYB"
//       });
//     }
//   }
//   createChargeBeeInstance();

//   return (
//     <div className="bg-white">

//       {/* Pricing with four tiers and toggle */}
//       <div className="bg-gradient-to-b from-white to-gray-50">
//         <div className="max-w-7xl mx-auto pt-10 px-4 sm:px-6 lg:px-8">
//           <div className="sm:flex sm:flex-col sm:align-center">
//             <h1 className="text-4xl font-extrabold text-gray-900 sm:text-center">Pricing Plans</h1>
//           </div>
//           <div className="mt-5 space-y-4 sm:mt-16 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-6 lg:max-w-4xl lg:mx-auto xl:max-w-none xl:mx-0 xl:grid-cols-4">
//             {Tiers.map((tier) => (
//               <div key={tier.name} className="border border-gray-200 rounded-lg shadow-sm divide-y divide-gray-200">
//                 <div className="p-6">
//                   <h2 className="text-lg leading-6 font-medium text-gray-900">{tier.name}</h2>
//                   <p className="mt-4 text-sm text-gray-500">{tier.description}</p>
//                   <p className="mt-8">
//                     <span className="text-4xl font-extrabold text-gray-900">${tier.priceMonthly}</span>{' '}
//                     <span className="text-base font-medium text-gray-500">/mo</span>
//                   </p>
//                   <a
//                     href="#"
//                     onClick={createOrUpdateSubscription}
//                     className="mt-8 block w-full border border-transparent rounded-md py-2 text-sm font-semibold text-center hover:bg-purple-700"
//                     style={{
//                       backgroundColor: tier.name !== activeTier ? "rgb(30 27 75)" : "rgb(205, 205, 205)",
//                       color:  tier.name !== activeTier ? "white" : "black",
//                     }}
//                   >
//                     {tier.name === activeTier ? `Current Plan` : `Buy ${tier.name}`}
//                   </a>
//                 </div>
//                 <div className="pt-6 pb-8 px-6">
//                   <h3 className="text-xs font-medium text-gray-900 tracking-wide uppercase">What's included</h3>
//                   <ul className="mt-6 space-y-4">
//                     {getFeatures(tier.features).map((feature) => (
//                       <li key={feature} className="flex space-x-3">
//                         <CheckIconSolid className="flex-shrink-0 h-5 w-5 text-green-500" aria-hidden="true" />
//                         <span className="text-sm text-gray-500">{feature}</span>
//                       </li>
//                     ))}
//                   </ul>
//                 </div>
//               </div>
//             ))}
//           </div>
//         </div>

//       </div>
//     </div>
//   )
// }