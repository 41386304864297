import { Fragment, useEffect, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XIcon, MenuIcon, DocumentDownloadIcon } from '@heroicons/react/outline'
import logo from './assets/Logo.png'
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/solid'

export function TextBoxWithLabel({options}) {
    options.grid = options.grid || "sm:col-span-4";
    options.autoComplete = options.autoComplete || "";
    return (
        <div className={options.grid}>
        <label htmlFor="username" className="block text-sm font-medium text-gray-700">
          {options.label}
        </label>
        <div className="mt-1 flex rounded-md shadow-sm">
          <input
            type={options.type}
            name={options.name}
            id={options.name}
            defaultValue={options.value}
            autoComplete={options.autoComplete}
            className="flex-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300"
          />
        </div>
      </div>
    )
}

export function ColorPicker({options}) {
  return (
  <div class="sm:col-span-4">
    <label className="block text-sm font-medium text-gray-700">{options.label}</label>
    <p className="text-sm leading-6 text-gray-500">{options.subtext}</p>
    <input id={options.id} className="mt-1" type="color" defaultValue={options.value || "#1E3A8A"} />
  </div>)
}

export function PrefixedInput({options}) {
  return (
    <div className="sm:col-span-3">
      {options.label && <label htmlFor={options.name} className="block text-sm font-medium text-gray-700">
        {options.label}
      </label>}
      <div className="flex rounded-md shadow-sm">
        <span className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm">
        {options.prefix}
        </span>
        <input
          type="text"
          name={options.name}
          id={options.name}
          className="flex-1 min-w-0 block w-full px-3 py-2 rounded-none rounded-r-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300"
          placeholder={options.placeholder}
        />
      </div>
    </div>
  )
}

export function TextArea({options}) {
    return (
    <div className="sm:col-span-4">
        <label htmlFor={options.name} className="block text-sm font-medium leading-6 text-gray-700">
        {options.label}
        </label>
        {options.pretext && <p className="mt-1 text-sm leading-6 text-gray-500">{options.pretext}</p>}
        <div className="mt-2">
          <textarea
              id={options.name}
              name={options.name}
              rows={options.rows}
              className="flex-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300"
              // className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              defaultValue={options.value}
              placeholder={options.placeholder}
          />
        </div>
        {options.subtext && <p className="mt-3 text-sm leading-6 text-gray-500">{options.subtext}</p>}
    </div>
    )
}

export function ProfilePic() {
  return (
    <div className="sm:col-span-6">
    <label htmlFor="photo" className="block text-sm font-medium text-gray-700">
      Profile Picture
    </label>
    <div className="mt-1 flex items-center">
      <span className="h-12 w-12 rounded-full overflow-hidden bg-gray-100">
        <svg className="h-full w-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
          <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
        </svg>
      </span>
      <button
        type="button"
        className="ml-5 bg-white py-2 px-3 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      >
        Change
      </button>
    </div>
  </div>)
}

export function Checkbox({options}) {
  return (
  <div className="relative flex items-start">
    <div className="flex items-center h-5">
      <input
        id={options.name}
        name={options.name}
        type="checkbox"
        className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
      />
    </div>
    <div className="ml-3 text-sm">
      <label htmlFor={options.name} className="font-medium text-gray-700">
        {options.label}
      </label>
      <p className="text-gray-500">{options.sublabel}</p>
    </div>
  </div>)
}

export function RadioButton({options}) {
  return (
  <div className="flex items-center">
    <input
      id={options.name}
      name={options.name}
      type="radio"
      className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
    />
    <label htmlFor={options.name} className="ml-3 block text-sm font-medium text-gray-700">
      {options.label}
    </label>
  </div>)
}

export function Selector({options}) {
  return(
  <div className={options.style || "sm:col-span-3"}>
    <label htmlFor={options.name} className="block text-sm font-medium text-gray-700">
      {options.label}
    </label>
    <div className="mt-1">
      <select
        id={options.name}
        name={options.name}
        defaultValue={options.value}
        className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
      >
        {options.items.map(item => <option>{item}</option>)}
      </select>
    </div>
  </div>)
}


export function Table({options}) {
    const rootStyle = options.style || "flex flex-col";
    return (
    <div class={rootStyle}>
        <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                <table class="min-w-full divide-y divide-gray-200">
                <thead class="bg-gray-50">
                    <tr>
                    {options.header.map(item => (
                    <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        {item}
                    </th>))}
                    {options.edit && <th scope="col" class="relative px-6 py-3"> <span class="sr-only">Refresh</span> </th>}
                    </tr>
                </thead>
                <tbody class="bg-white divide-y divide-gray-200">
                    {options.rows.map(row => {
                        const text_style = row === options.rows[0] ? "font-medium text-gray-900" : "text-gray-500";
                        const full_style = "px-6 py-4 whitespace-nowrap text-sm " + text_style
                        return (
                        <tr>
                            {row.map(col => (<td class={full_style}> {col} </td>))}
                            {options.edit && <td class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                                                <a href="#" class="text-indigo-600 hover:text-indigo-900">Refresh</a>
                                            </td>}
                        </tr>
                    )})}
                </tbody>
                </table>
            </div>
            </div>
        </div>
    </div>)
}


export function ModalDialog({options}) {
  const cancelButtonRef = useRef(null);

  return (
    <Transition.Root show={options.open} as={Fragment}>
      <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={options.setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                      <DocumentDownloadIcon className="h-6 w-6 text-indigo-600" aria-hidden="true" />
                    </div>
                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                      <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                        {options.title}
                      </Dialog.Title>
                      <div className="mt-2">
                        {options.content()}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                  {options.primary &&
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 sm:ml-3 sm:w-auto"
                      onClick={() => options.setOpen(false)}
                    >
                      {options.primary}
                    </button>}
                  {options.secondary &&
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                      onClick={() => options.setOpen(false)}
                      ref={cancelButtonRef}
                    >
                      {options.secondary}
                    </button>}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export function SlideOver({options}) {
  return (
    <Transition.Root show={options.open} as={Fragment}>
      <Dialog as="div" static className="fixed inset-0 overflow-hidden" open={options.open} onClose={options.setOpen}>
        <div className="absolute inset-0 overflow-hidden">
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>
          <div className="fixed inset-y-0 right-0 pl-10 max-w-full flex">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div className="relative w-screen max-w-lg">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-500"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-500"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute top-0 left-0 -ml-8 pt-4 pr-2 flex sm:-ml-10 sm:pr-4">
                    <button
                      className="rounded-md text-gray-300 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                      onClick={() => options.setOpen(false)}
                    >
                      <span className="sr-only">Close panel</span>
                      <XIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                </Transition.Child>
                <div className="h-full flex flex-col py-6 bg-white shadow-xl overflow-y-scroll">
                  <div className="px-4 sm:px-6">
                    <Dialog.Title className="text-lg font-medium text-gray-900">{options.title}</Dialog.Title>
                  </div>
                  <div className="mt-6 relative flex-1 px-4 sm:px-6">
                    {options.content && options.content()}
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export function deepCopy(obj) {
  return JSON.parse(JSON.stringify(obj))
}

export default function Outline({options}) {
    const navigation = options.nav;
    const [openSlideOver, setOpenSlideOver] = useState(false);
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(navigation.map(item => item.name).indexOf(options.show));

    navigation.forEach((item, i, arr) => {
        arr[i].current = (i === selectedIndex);
    });

    const handleClick = (e) => {
        const names = navigation.map(item => item.name);
        const index = names.indexOf(e.target.innerText);
        setSelectedIndex(index);
        navigation.forEach((item, i, arr) => {
            arr[i].current = (i === index);
            if (arr[i].current && arr[i].click)
                arr[i].click();
        });
        e.preventDefault();
    };

    return (
        <>
        <SlideOver options={{
          open: openSlideOver,
          setOpen: setOpenSlideOver,
          content: navigation[selectedIndex].slideOver,
          title: "More Info"
        }} />
        <div className="h-screen flex overflow-hidden bg-gray-100">
        <Transition.Root show={sidebarOpen} as={Fragment}>
            <Dialog
            as="div"
            static
            className="fixed inset-0 flex z-40 md:hidden"
            open={sidebarOpen}
            onClose={setSidebarOpen}
            >
            <Transition.Child
                as={Fragment}
                enter="transition-opacity ease-linear duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition-opacity ease-linear duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
            >
                <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
            </Transition.Child>
            <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
            >
                <div className="relative flex-1 flex flex-col max-w-xs w-full bg-indigo-700">
                <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="absolute top-0 right-0 -mr-12 pt-2">
                    <button
                        className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                        onClick={() => setSidebarOpen(false)}
                    >
                        <span className="sr-only">Close sidebar</span>
                        <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
                    </button>
                    </div>
                </Transition.Child>
                <div className="flex-1 h-0 pt-5 pb-4 overflow-y-auto">
                    <div className="flex-shrink-0 flex items-center px-4">
                    <img
                        className="h-8 w-auto"
                        src={logo}
                        alt="Workflow"
                    />
                    </div>
                    <nav className="mt-5 px-2 space-y-1">
                    {navigation.filter(item => item.enabled).map((item) => (
                        <a
                        key={item.name}
                        onClick={handleClick}
                        href="#"
                        className={classNames(
                            item.current
                            ? 'bg-indigo-800 text-white'
                            : 'text-white hover:bg-indigo-600 hover:bg-opacity-75',
                            'group flex items-center px-2 py-2 text-base font-medium rounded-md'
                        )}
                        >
                        <item.icon className="mr-4 flex-shrink-0 h-6 w-6 text-indigo-300" aria-hidden="true" />
                        {item.name}
                        </a>
                    ))}
                    </nav>
                </div>

                </div>
            </Transition.Child>
            <div className="flex-shrink-0 w-14" aria-hidden="true">
                {/* Force sidebar to shrink to fit close icon */}
            </div>
            </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div className="hidden bg-indigo-950 md:flex md:flex-shrink-0">
            <div className="flex flex-col w-64">

            {/* Sidebar component, swap this element with another sidebar if you like */}
            <div className="flex flex-col h-0 flex-1">
                <div className="flex-1 flex flex-col pt-5 pb-4 overflow-y-auto">

                <div className="flex items-center flex-shrink-0 px-4">
                    <img
                    className="h-8 w-auto"
                    src={logo}
                    alt="Workflow"
                    />
                </div>

                <nav className="mt-5 flex-1 px-2 space-y-1">
                    {navigation.filter(item => item.enabled).map((item) => (
                    <a
                        key={item.name}
                        href="#"
                        onClick={handleClick}
                        className={classNames(
                        item.current ? 'bg-indigo-600 text-white' : 'text-white hover:bg-indigo-600 hover:bg-opacity-75',
                        'group flex items-center px-2 py-2 text-sm font-medium rounded-md'
                        )}
                    >
                        <item.icon className="mr-3 flex-shrink-0 h-6 w-6 text-indigo-300" aria-hidden="true" />
                        {item.name}
                    </a>
                    ))}
                </nav>
                </div>
                
            </div>
            </div>
        </div>

        <div className="flex flex-col w-0 flex-1 overflow-hidden">
            <div className="md:hidden pl-1 pt-1 sm:pl-3 sm:pt-3">
            <button
                className="-ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                onClick={() => setSidebarOpen(true)}
            >
                <span className="sr-only">Open sidebar</span>
                <MenuIcon className="h-6 w-6" aria-hidden="true" />
            </button>
            </div>
            <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none">
            <div className="py-6">
                <div className="max-w-[95%] mx-auto px-4 sm:px-6 md:px-8">
                    {navigation[selectedIndex].content(setOpenSlideOver)}
                </div>
            </div>
            </main>
        </div>

        </div>
        </>
    )
}

export function range(start, end) {
  return [...Array(end - start + 1).keys()].map(i => i + start);
}

export function Pagination({pages, selectPage}) {
  const pageClass = (current) => {
    return current ? "z-10 bg-indigo-50 border-indigo-500 text-indigo-600 relative inline-flex items-center px-4 py-2 border text-sm font-medium"
            : "bg-white border-gray-300 text-gray-500 hover:bg-gray-50 relative inline-flex items-center px-4 py-2 border text-sm font-medium";
  };

  const last = Math.floor(pages.total / pages.count) + 1, first = 1;
  const prefix = pages.current > 1 ? [-1] : [];
  const rest = last < 6 ? range(first, last) : [pages.current, pages.current+1, -1, last-1, last];
  const pageList = prefix.concat(rest);

  return (
    <div className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6">
      <div className="flex-1 flex justify-between sm:hidden">
        <a
          href="#"
          className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
        >
          Previous
        </a>
        <a
          href="#"
          className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
        >
          Next
        </a>
      </div>
      <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
        <div>
          <p className="text-sm text-gray-700">
            Showing <span className="font-medium">{pages.offset + 1}</span> to <span className="font-medium">{pages.offset + pages.count}</span> of{' '}
            <span className="font-medium">{pages.total}</span> results
          </p>
        </div>
        <div>
          <nav className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination">
            <a
              href="#"
              onClick={selectPage.bind(null, pages.current - 1)}
              className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
            >
              <span className="sr-only">Previous</span>
              <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
            </a>
            {pageList.map(pageIndex => {
              return (pageIndex === -1) ? (
                <span className="relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700">...</span>
              )
              : (<a href="#" onClick={selectPage.bind(null, pageIndex)} aria-current="page" className={pageClass(pages.current === pageIndex)}>
                  1
                 </a>)
            })}
            <a
              href="#"
              onClick={selectPage.bind(null, pages.current + 1)}
              className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
            >
              <span className="sr-only">Next</span>
              <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
            </a>
          </nav>
        </div>
      </div>
    </div>
  )
}

export function Tabs({tabs, options, onClick}) {
  return (
    <div className=''>
      <h3 className="text-lg font-medium text-gray-900 mb-4">{options.title}</h3>
      {options.selector &&
      <div className={options.tabbed ? "sm:hidden" : ""}>
        <select
          id="tabs"
          name="tabs"
          onChange={onClick}
          data-name="tabs-selector"
          className="block w-full focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
          defaultValue={tabs.find((tab) => tab.current).name}
        >
          {tabs.map((tab) => (
            <option key={tab.name} data-name={tab.name}>{tab.name}</option>
          ))}
        </select>
      </div>}

      {options.tabbed &&
      <div className="hidden sm:block">
        <nav className="relative z-0 rounded-lg shadow flex divide-x divide-gray-200" aria-label="Tabs">
          {tabs.map((tab, tabIdx) => (
            <a
              key={tab.name}
              href={tab.href}
              data-name={tab.name}
              onClick={onClick}
              className={classNames(
                tab.current ? 'text-gray-900' : 'text-gray-500 hover:text-gray-700',
                tabIdx === 0 ? 'rounded-l-lg' : '',
                tabIdx === tabs.length - 1 ? 'rounded-r-lg' : '',
                'group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-4 text-sm font-medium text-center hover:bg-gray-50 focus:z-10'
              )}
              aria-current={tab.current ? 'page' : undefined}
            >
              <span>{tab.name}</span>
              <span
                aria-hidden="true"
                className={classNames(
                  tab.current ? 'bg-indigo-500' : 'bg-transparent',
                  'absolute inset-x-0 bottom-0 h-0.5'
                )}
              />
            </a>
          ))}
        </nav>
      </div>}
      <div className="border-b-2 mt-5"></div>
    </div>
  )
}
